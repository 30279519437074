import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, json } from "react-router-dom"
import Header from "../Header";
import { Table, Input, Button, Popconfirm } from "antd";
import { FetchBatchData, UpdateSingleClassOfBatch, updateBatchStatus } from "../../api/aws-api";
import { ClassicSpinner, CombSpinner, MagicSpinner } from "react-spinners-kit";
import Swal from 'sweetalert2'
import { gapi } from 'gapi-script';
import { ConstructorEventGet, ConstructorEventPost } from "../../Event/event";
import { FetchStudentDetails } from "../../api/aws-api";





const EditBatch = ({ meetLink }) => {
  const [status, setStatus] = useState("")
  const [classstatus, setClassStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [meetlink, setMeetLink] = useState()
  const [startDateTime, setStartDateTime] = useState()
  const [endDateTime, setEndDateTime] = useState()
  const [editDate, setEditDate] = useState()
  const [signedIn, setSignedIn] = useState(false);
  const [gmeetLink, setgMeetLink] = useState('');
  const [calenderEvents, setCalenderEvents] = useState()
  const [selectedClass, setSelectedClass] = useState()
  const [editmeetlink, setEditMeetLink] = useState(false)
  const [editbatchstatus, setEditBatchStatus] = useState(false)
  const location = useLocation();
  const [batchdetails, setBatchDetails] = useState()
  const navigate = useNavigate()
  const [userType, setUserType] = useState("")
  const [addStudentClicked, setAddStudentClicked] = useState(false)
  const [studentDetails, setStudentDetails] = useState([])
  const [addedStudentsToTheBatch, setAddedStudentToTheBatch] = useState([])
  const [isCompletedVisible, setIsCompletedVisible] = useState(false);

  const [addedStudents, setAddedStudents] = useState([])


  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [preselectedStudents, setPreSelectedStudents] = useState()
  const [enableSubmitAddStudentButton, setEnableSubmitAddStudentButton] = useState(false)
  const [studentsAlreadyInBatch, setStudentsAlreadyInBatch] = useState()
  const [meetEventId, setMeetEventId] = useState("")
  const [mentorMailId, setMentorMailId] = useState("")
  const [matchingId, setMatchingId] = useState("");
  const [studentsMail, setStudentsMail] = useState();


  const CLIENT_ID = '347764156937-klkgu25nrg2l7pq8iticmrvha29f3vr7.apps.googleusercontent.com';
  const API_KEY = 'AIzaSyCio8YcxXtf28_wN_ZWIHIjrlmzjGbXTJg';
  const SCOPES = 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/admin.reports.audit.readonly';



  useEffect(() => {


    function start() {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: SCOPES,
      }).then(() => {
        console.log("Google API initialized successfully.");
        gapi.client.load('calendar', 'v3'); // Load the Calendar API library
        const authInstance = gapi.auth2.getAuthInstance();
        authInstance.signIn();
        setSignedIn(authInstance.isSignedIn.get());
        authInstance.isSignedIn.listen(setSignedIn);
      }).catch(error => {
        console.error("Error initializing Google API:", error);
      });
    }

    gapi.load('client:auth2', start);




    // console.log("the stored data is", storedData)
    // if (storedData !== "undefined" && storedData !== "null" && storedData !== undefined && storedData !== null) { setCourseOptions(JSON.parse(storedData)) }
    const { batch } = location.state ? location.state : []

    const selectedBatchSession = sessionStorage.getItem("selectedBatch")

    selectedBatchSession ? setBatchDetails(JSON.parse(selectedBatchSession)) : setBatchDetails(batch)

    // console.log("sessionBatch in local",JSON.parse(selectedBatchSession))
    // setBatchDetails(batch)

    const extractedPart = batch.ClassSchedule[0].meet_link



    console.log("the batch is", batch);

    console.log("the batch in the editbatch  is", extractedPart)

    // const mentorDetails = JSON.parse(batch)
    // console.log("mentorDetails", mentorDetails);
    const filteredBatch = batch
    console.log("the batch in the editbatch  is", filteredBatch)
    const mentorDetails = batch.mentorName;
    console.log(mentorDetails, "mentor details");
    setMeetEventId(extractedPart)
    setMentorMailId('bharathmanojdev@gmail.com')
    const type = JSON.parse(sessionStorage.getItem("UserData"))
    setUserType(type.user_type)
    fetchStudentDetails()
    console.log("fgfdgfdgfdgdgfdgfd", batch.EventId)


    const studentsAlreadyEnrolled = selectedBatchSession ? JSON.parse(selectedBatchSession).StudentsEndrolled.map((ele) => {
      return ele.key
    }) : batch.StudentsEndrolled.map((ele) => {
      return ele.key
    })

    localStorage.setItem("studentsAlreadyEnrolled", JSON.stringify(studentsAlreadyEnrolled));

    console.log("batccjhh", studentsAlreadyEnrolled)
    setPreSelectedStudents(studentsAlreadyEnrolled)
    console.log(preselectedStudents, "preselcted");
    setSelectedRowKeys(studentsAlreadyEnrolled)
    console.log(selectedRowKeys, "selectedRows");

    getBatchDatas()

  }, [])

  useEffect(() => {
    if (batchdetails && batchdetails.ClassSchedule) {
      const allCompleted = batchdetails.ClassSchedule.every(
        (item) => item.status === "Completed"
      );
      setIsCompletedVisible(allCompleted);
    }
  }, [batchdetails]);

  useEffect(() => {
    console.log("Updated students' emails:", studentsMail);
  }, [studentsMail]);


  const getBatchDatas = async () => {
    const responsedata = await ConstructorEventGet(FetchBatchData, { "mentorId": "" })
    console.log("the response data is ", responsedata
    )


    const removeCancelledBatches = responsedata.filter((ele) => {
      return ele.batchProgress != "Cancelled"
    })


    const removeCompletedBatches = removeCancelledBatches.filter((ele) => {
      return ele.batchProgress != "Completed"
    })

    console.log("the batches are", removeCompletedBatches)

    const finalData = removeCompletedBatches.map((ele) => {
      return ele.StudentsEndrolled.map((ele) => {
        return ele.key
      })
    })

    console.log("the final data is", finalData)

    const studentsId = [...new Set(finalData.flat())]

    setStudentsAlreadyInBatch(studentsId)
    console.log("the students enrolled are", studentsId)

  }

  const fetchStudentDetails = async () => {
    const responsedata = await ConstructorEventGet(FetchStudentDetails)
    setStudentDetails(responsedata)
    console.log("the rsponse data", responsedata)

    const studentdata = responsedata.filter((ele) => {
      return ele.user_type === "student"
    })

    console.log("the student data is", studentdata)

    setFilteredData(studentdata)
    setStudentDetails(studentdata)
    // setFilteredData(responsedata)
  }


  const removeStudentFromBatch = (studentKey) => {
    console.log("meeting link used to find id ", meetEventId);

    gapi.client.calendar.events.list({
      calendarId: 'primary',
    }).then(response => {
      console.log("All events:", response.result.items);
      const events = response.result.items;
      const matchingEvent = events.find(event => event.hangoutLink === meetEventId);

      if (matchingEvent) {
        console.log("Matching event ID:", matchingEvent.id);
        setMatchingId(matchingEvent.id);
        // You can now use matchingEvent.id as needed
      } else {
        console.log("No event found with the provided meet link.");
      }
    }).catch(error => {
      console.error("Error fetching events:", error);
    });

    console.log("the key will be", studentKey, "and the batch details is", batchdetails);




    const deleteStudent = batchdetails.StudentsEndrolled.filter((ele) => {
      return ele.key != studentKey
    })
    batchdetails.StudentsEndrolled = deleteStudent
    setBatchDetails(batchdetails)
    const student = batchdetails.StudentsEndrolled.map((ele) => {
      return ele.key
    })
    setPreSelectedStudents(student)
    console.log(preselectedStudents, "hi pre");

    setSelectedRowKeys(student)
    const params = {
      "mentorId": batchdetails.mentorId,
      "BatchName": batchdetails.BatchName,
      "batchProgress": batchdetails.batchProgress,
      "StudentsEndrolled": batchdetails.StudentsEndrolled,
      "BatchId": batchdetails.BatchId,
      "mentorId": batchdetails.mentorId,
      "mentorName": batchdetails.mentorName,
      "courseId": batchdetails.courseId,
      "courseName": batchdetails.courseName,
      "isStudentRemoved": true,
      "studentId": studentKey
    }
    updateDatainCloudForRemove(params)
    setSelectedRows([])
    setSearchValue("")
    setFilteredData(studentDetails)
    setAddStudentClicked(false)
    setEnableSubmitAddStudentButton(false)


  };





  const enrolledrowSelection = {




    selectedRowKeys,
    onChange: (selectedKeys, selectedRecords) => {

      console.log("the selected keys are", selectedKeys)
      const storedEnrolledStudents = JSON.parse(localStorage.getItem("studentsAlreadyEnrolled")) || [];
      console.log("storedEnroled", storedEnrolledStudents);

      // Combine initially enrolled and newly selected students
      const updatedSelectedKeys = Array.from(new Set([...storedEnrolledStudents, ...selectedKeys]));
      setEnableSubmitAddStudentButton(true)
      setSelectedRowKeys(updatedSelectedKeys);
      console.log(updatedSelectedKeys, "selcted roew key 1");

      localStorage.setItem("studentsAlreadyEnrolled", JSON.stringify(updatedSelectedKeys));

      const updatedSelectedRecords = updatedSelectedKeys.map(key =>
        studentDetails.find(student => student.key === key)
      ).filter(record => record); // Filter out any undefined records in case of missing keys

      // Set updated selected records to state
      setSelectedRows(updatedSelectedRecords);
      // Printing the selected row data
      updatedSelectedRecords.forEach(row => {
        console.log(`Selected Name: ${row.name}`);
      });
    },
    getCheckboxProps: (record) => ({
      disabled: studentsAlreadyInBatch.includes(record.key), // Disable checkbox if the student is preselected
    }),
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value)

    const userStudents = studentDetails.filter((ele) => {
      return ele.user_type === "student"
    })

    const searchedValue = userStudents.filter((ele) => {
      return ele.name.toLowerCase().includes(e.target.value.toLowerCase()) || ele.mobile.includes(e.target.value)
    })
    setFilteredData(searchedValue)
  };

  const columns = [
    {
      title: "Students",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
    },
    ...(batchdetails && batchdetails.batchProgress !== "Completed"
      ? [
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <Popconfirm
              title="Are you sure you want to delete this student?"
              onConfirm={() => removeStudentFromBatch(record.key)}
              okButtonProps={{ className: 'popconfirm-ok-button' }}
            >
              <Button type="primary" style={{ backgroundColor: "red" }}>
                Remove
              </Button>
            </Popconfirm>
          ),
        },
      ]
      : [])
  ];

  const columnss = [
    {
      title: "Students",
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ]
  const columnsClassSchedule = [
    {
      title: "Class Id",
      dataIndex: "scheduleNumber",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Start",
      dataIndex: "startDateTime",
    },
    {
      title: "End",
      dataIndex: "endDateTime",
    },
    {
      title: "Meet Link",
      dataIndex: "meet_link",
    },
    {
      title: "Status",
      dataIndex: "status",
    }
  ];

  const savechanges = async () => {
    console.log("start and enddate is", editDate)

    const editDatee = editDate.split("T")[0]
    // const startTime = startDateTime.split("T")[1]
    // const endTime = endDateTime.split("T")[1]

    // console.log("the editDate", editDatee)
    console.log("startTime", startDateTime)
    console.log("endTime", endDateTime)
    // console.log("sttt", `${editDate}T${startTime}`)
    // console.log("sttt", `${editDate}T${endTime}`)

    const editedStartedDate = `${editDatee}T${startDateTime}:00`
    const editedEndDate = `${editDatee}T${endDateTime}:00`


    const params = {
      "mentorId": batchdetails.mentorId,
      "BatchName": batchdetails.BatchName,
      "meet_link": meetlink,
      "scheduleNumber": selectedClass.scheduleNumber,
      "status": classstatus,
      "startDateTime": editedStartedDate,
      "endDateTime": editedEndDate
    }
    setLoading(true)
    const responsedata = await ConstructorEventPost(UpdateSingleClassOfBatch, params)
    if (responsedata[0] === "updated") { setLoading(false); setBatchDetails(responsedata[1]); setEditBatchStatus(false); setEditMeetLink(false); setSelectedClass(""); setMeetLink(""); setClassStatus(""); setEditDate(""); setStartDateTime(""); setEndDateTime(""); }
    else { setLoading(false) }
  }
  const updateDatainCloud = async (params) => {

    setLoading(true)
    const responsedata = await ConstructorEventPost(updateBatchStatus, params)
    if (responsedata[0] === "updated") {
      console.log("the response data in adding batchstatus is", responsedata[1]);

      const studentsAlreadyEnrolled = responsedata[1].StudentsEndrolled.map(student => student.key);
      localStorage.setItem("studentsAlreadyEnrolled", JSON.stringify(studentsAlreadyEnrolled));

      sessionStorage.setItem("selectedBatch", JSON.stringify(responsedata[1])); setLoading(false); setBatchDetails(responsedata[1]); setEditBatchStatus(false); setEditMeetLink(false); setStatus("")
    }
    else { setLoading(false) }

  }
  const updateDatainCloudForRemove = async (params) => {

    setLoading(true)
    const responsedata = await ConstructorEventPost(updateBatchStatus, params)
    if (responsedata[0] === "updated") {
      console.log("the response data in adding batchstatus is", responsedata[1]);

      const studentsAlreadyEnrolled = responsedata[1].StudentsEndrolled.map(student => student.key);
      localStorage.setItem("studentsAlreadyEnrolled", JSON.stringify(studentsAlreadyEnrolled));

      sessionStorage.setItem("selectedBatch", JSON.stringify(responsedata[1])); setLoading(false); setBatchDetails(responsedata[1]); setEditBatchStatus(false); setEditMeetLink(false); setStatus("")

      const enrolledStudentsMail = batchdetails.StudentsEndrolled.map(student => ({
        email: student.email,
      }));

      console.log("Enrolled students in the required format:", enrolledStudentsMail);

      

      
  gapi.client.calendar.events.list({
    calendarId: 'primary',
  }).then(response => {
    console.log("All events:", response.result.items);
    const events = response.result.items;
    const matchingEvent = events.find(event => event.hangoutLink === meetEventId);

    if (matchingEvent) {
      console.log("Matching event ID:", matchingEvent.id);
      const matchingId = matchingEvent.id;


      
      // Update the event with new attendees
      gapi.client.calendar.events.patch({
        calendarId: 'primary',
        eventId: matchingId,
        resource: {
          attendees: enrolledStudentsMail,
        },
  
      }).then(updatedResponse => {
        console.log('Updated event:', updatedResponse.result);
      }).catch(error => {
        console.error('Error updating attendees:', error);
      });
  
  
  
      // You can now use matchingEvent.id as needed
    } else {
      console.log("No event found with the provided meet link.");
    }
  }).catch(error => {
    console.error("Error fetching events:", error);
  });


    }
    else { setLoading(false) }

  }
  const UpdateBatchProgress = (statusupdate) => {


    const params = {
      "mentorId": batchdetails.mentorId,
      "BatchName": batchdetails.BatchName,
      "batchProgress": statusupdate,
      "StudentsEndrolled": batchdetails.StudentsEndrolled
    }
    Swal.fire({
      title: statusupdate === "Cancelled" ? "Do you wish to cancel this batch?" :
        statusupdate === "InProgress" ? "Would you like to switch the status of this batch to in progress?" : statusupdate === "Completed" ? "Would you like to switch the status of this batch to Completed ?" : "",
      text: statusupdate === "Cancelled" ? "Cancel " + batchdetails.BatchName + " ?" :
        statusupdate === "InProgress" ? "Started " + batchdetails.BatchName + " ?" : statusupdate === "Completed" ? "Completed" + batchdetails.BatchName + " ?" : "",
      icon: statusupdate === "InProgress" ? "success" : statusupdate === "Completed" ? "success" : "warning",
      iconColor: statusupdate === "InProgress" ? "#4df766" : statusupdate === "Completed" ? "#4df766" : "#fa414a",
      confirmButtonText: 'Yes, please',
      confirmButtonColor: "#FF7E84",
      cancelButtonColor: "#30BEAD",
      showCancelButton: true,
      cancelButtonText: "Nope,Changed my mind",
    }).then((result) => {
      if (result.isConfirmed) {
        updateDatainCloud(params)

        // if (statusupdate === "InProgress") {
        //   Swal.fire({
        //     title: 'Meet Link',
        //     html: `<input id="swal-input" type="text" class="swal2-input from-control-1" value="${gmeetLink}" readonly>`,
        //     showCancelButton: true,
        //     confirmButtonText: 'Proceed',
        //     cancelButtonText: 'Cancel',
        //     confirmButtonColor: "#FF7E84",
        //     cancelButtonColor: "#30BEAD",
        //     preConfirm: () => {
        //       const input = document.getElementById('swal-input');
        //       if (input) {
        //         params["meet_link"] = input.value;
        //       }
        //     }
        //   }).then((confirm) => {
        //     if (confirm.isConfirmed) {
        //       updateDatainCloud(params);
        //     }
        //   });

        // }
        // else { updateDatainCloud(params) }
      }
    });
  }

  const selectStudent = () => {
    setAddStudentClicked(true)
  }


  const addStudentToTheBatch = () => {

    console.log("meeting link used to find id ", meetEventId);

    gapi.client.calendar.events.list({
      calendarId: 'primary',
    }).then(response => {
      console.log("All events:", response.result.items);
      const events = response.result.items;
      const matchingEvent = events.find(event => event.hangoutLink === meetEventId);

      if (matchingEvent) {
        console.log("Matching event ID:", matchingEvent.id);
        const matchingId = matchingEvent.id;


        const updatedAttendees = newlyAddedStudentsToTheBatch.map((ele) => {
          return { 'email': ele.email }
        })
    
        updatedAttendees.push({ 'email': mentorMailId })
    
        console.log("the updatedAttendees are", updatedAttendees)
    
        // Update the event with new attendees
        gapi.client.calendar.events.patch({
          calendarId: 'primary',
          eventId: matchingId,
          resource: {
            attendees: updatedAttendees,
          },
    
        }).then(updatedResponse => {
          console.log('Updated event:', updatedResponse.result);
        }).catch(error => {
          console.error('Error updating attendees:', error);
        });
    
    
    
        // You can now use matchingEvent.id as needed
      } else {
        console.log("No event found with the provided meet link.");
      }
    }).catch(error => {
      console.error("Error fetching events:", error);
    });

    

   
    const newlyAddedStudentsToTheBatch = [...selectedRows]
    console.log("tttttt", newlyAddedStudentsToTheBatch)

    const newlyAddedStudents = newlyAddedStudentsToTheBatch.map((ele) => {
      return ele.key
    })

    console.log("newly selected Students", newlyAddedStudents)
    console.log("prestudent", preselectedStudents);

    console.log(`the batch id is ${batchdetails.BatchId} the mentor id is ${batchdetails.mentorId} the mentor name is ${batchdetails.mentorName} the course id is ${batchdetails.courseId} the course name is ${batchdetails.courseName}`)
    setPreSelectedStudents(newlyAddedStudents)
    setAddedStudentToTheBatch(newlyAddedStudentsToTheBatch)
    const params = {
      "mentorId": batchdetails.mentorId,
      "BatchName": batchdetails.BatchName,
      "batchProgress": batchdetails.batchProgress,
      "StudentsEndrolled": newlyAddedStudentsToTheBatch,
      "BatchId": batchdetails.BatchId,
      "mentorId": batchdetails.mentorId,
      "mentorName": batchdetails.mentorName,
      "courseId": batchdetails.courseId,
      "courseName": batchdetails.courseName,
      "isStudentAdded": true
    }
    console.log("the params is", params)
    updateDatainCloud(params)
    console.log("newly students enrolled", newlyAddedStudentsToTheBatch)
    localStorage.setItem("studentsAlreadyEnrolled", JSON.stringify(preselectedStudents));

    setSelectedRows([])
    setSearchValue("")
    setFilteredData(studentDetails)
    setAddStudentClicked(false)
    setEnableSubmitAddStudentButton(false)
  }


  const cancelAddingStudentToTheBatch = () => {
    const preselectedKeys = selectedRowKeys.filter((key) => preselectedStudents.includes(key));
    console.log(preselectedKeys, "preselectedkeys")

    const preselectedRecords = selectedRows.filter((row) => preselectedStudents.includes(row.key));

    localStorage.setItem("studentsAlreadyEnrolled", JSON.stringify(preselectedKeys));

    // Reset the selected keys and rows to only include the preselected students
    setSelectedRowKeys(preselectedKeys);
    setSelectedRows(preselectedRecords);

    setAddStudentClicked(false)
    setEnableSubmitAddStudentButton(false)
  }

  const cancelEditChanges = () => {
    setEditDate("")
    setMeetLink("")
    setStartDateTime("")
    setEditMeetLink(false)
  }


  return (
    <>
      <Header />
      {loading ?
        <div className="spinner" >
          <div className="spinner-wrapper">
            <ClassicSpinner size={50} color="black" loading={loading} />
          </div></div> :
        <div className="nav-tab-wrapper tabs  section-padding">
          <div className="container">
            <div className="grid grid-cols-12 gap-[30px]">
              <div className="xl:col-span-4 lg:col-span-6 col-span-12 ">
                <div className="mini-title">Edit Your Batch</div>
                <div className="bg-white shadow-box7 p-4 rounded-md mt-8">
                  <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                    Course Name : {batchdetails && batchdetails.courseName}
                  </div>
                  <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                    Date : {batchdetails && batchdetails.StartDate} {"   →   "}{batchdetails && batchdetails.EndDate}
                    {/* ({batchdetails && batchdetails.Repeat}) */}
                  </div>
                  {/* <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                    Time : {batchdetails && batchdetails.StartTime} {"   →   "}{batchdetails && batchdetails.EndTime}
                  </div> */}
                  <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                    Mentor : {batchdetails && batchdetails.mentorName}
                  </div>
                  {/* {editbatchstatus ?
                    <div className="pb-4 mt-4"> Status</div>
                    : */}
                  <div className="fflex-none mr-6 mt-4  focus:ring-0 flex items-center">
                    Batch Status : {batchdetails && batchdetails.batchProgress}
                  </div>
                  {!editmeetlink && !editbatchstatus ?
                    <div>
                      <div style={{ display: "flex" }}>
                        {batchdetails && batchdetails.batchProgress === "InProgress" ? <button className="btn btn-secondary mt-4 mr-4" onClick={() => setEditMeetLink(true)}>Edit Class</button> : ""}
                        {batchdetails && batchdetails.batchProgress === "Scheduled" ? <button className="btn btn-secondary mt-4 mr-4" onClick={() => setEditBatchStatus(true)}>Change Status</button> : ""}
                        {batchdetails && batchdetails.batchProgress === "InProgress" ? <button className="btn btn-secondary mt-4 mr-4" onClick={() => setEditBatchStatus(true)}>Edit Batch Status</button> : ""}
                        {!addStudentClicked && userType === "admin" && batchdetails &&
                          (batchdetails.batchProgress === "Scheduled" || batchdetails.batchProgress === "InProgress") ? (
                          <button className="btn btn-secondary mt-4 mr-4" onClick={selectStudent}>
                            Add Student
                          </button>
                        ) : ""}


                      </div>
                      {userType === "admin" ? addStudentClicked && enableSubmitAddStudentButton ? <button className="btn btn-secondary mt-4 mr-4" onClick={addStudentToTheBatch}>Submit</button> : "" : ""}
                      {userType === "admin" ? addStudentClicked ? <button className="btn btn-secondary mt-4 mr-4" onClick={cancelAddingStudentToTheBatch}>Cancel</button> : "" : ""}


                    </div>
                    : editmeetlink && !editbatchstatus ?
                      <div>

                        <select
                          className="from-control focus:ring-0 mt-4 bg-[#ECECEC]"
                          onChange={(e) => {
                            console.log("tr", e.target.value)
                            const indclass = e.target.value && batchdetails.ClassSchedule.filter(obj => obj.scheduleNumber === parseInt(e.target.value))[0]; setClassStatus(indclass ? indclass["status"] : ""); setMeetLink(indclass ? indclass["meet_link"] : ""); setStartDateTime(indclass ? indclass["startDateTime"] : ""); setSelectedClass(indclass); setEndDateTime(indclass ? indclass["endDateTime"] : ""); setEditDate(indclass ? indclass["startDateTime"] : "");
                          }}
                          required>
                          <option key="" value="">Select Class</option>
                          {batchdetails && batchdetails.ClassSchedule.map((item1, index) => (
                            <option key={item1.scheduleNumber} value={item1.scheduleNumber}>{item1.startDateTime.split("T")[0]}</option>
                          ))}
                        </select>
                        <input
                          type="date"
                          className="from-control mt-4"
                          value={editDate ? editDate.split("T")[0] : ""}
                          onChange={(e) =>
                            setEditDate(e.target.value)}
                        />
                        <input
                          type="text"
                          className="from-control mt-4"
                          value={meetlink}
                          placeholder="Meet Link"
                          onChange={(e) => setMeetLink(e.target.value)}
                          required
                          readOnly
                        />
                        <input
                          type="time"
                          className="from-control mt-4"
                          value={startDateTime ? startDateTime.split("T")[1] : ""}
                          onChange={(e) =>
                            setStartDateTime(e.target.value)}
                        />
                        <input
                          type="time"
                          className="from-control mt-4"
                          value={endDateTime ? endDateTime.split("T")[1] : ""}
                          onChange={(e) =>
                            setEndDateTime(e.target.value)}
                        />
                        <div className="input-container">


                          <select
                            className="from-control p-4 bg-[#ECECEC] text-[#827878] mt-4"
                            value={classstatus}
                            onChange={(e) => setClassStatus(e.target.value)}
                            required
                          >
                            <option key="Scheduled" value="Scheduled">Scheduled</option>
                            <option key="InProgress" value="InProgress">InProgress</option>
                            <option key="Completed" value="Completed">Completed</option>
                          </select>


                        </div>
                        {selectedClass ? "" : <button className="btn btn-black mt-4 mr-4" onClick={() => setEditMeetLink(false)}>Cancel</button>}

                        {selectedClass ? selectedClass.meet_link !== meetlink ?
                          <div> <button className="btn btn-black mt-4 mr-4" onClick={savechanges} >Save Changes</button>
                            <button className="btn btn-black mt-4 mr-4" onClick={cancelEditChanges}>Cancel</button></div> : selectedClass.status !== classstatus ? <div> <button className="btn btn-black mt-4 mr-4" onClick={savechanges} >Save Changes</button>
                              <button className="btn btn-black mt-4 mr-4" onClick={cancelEditChanges}>Cancel</button></div> : selectedClass.startDateTime.split("T")[0] === editDate ? "" : <div> <button className="btn btn-black mt-4 mr-4" onClick={savechanges} >Save Changes</button>
                                <button className="btn btn-black mt-4 mr-4" onClick={cancelEditChanges}>Cancel</button></div> : ""}
                      </div> :
                      !editmeetlink && editbatchstatus ?
                        <div>
                          <div className="input-container">
                            <select
                              className="from-control p-4 bg-[#ECECEC] text-[#827878] "
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              required
                            >
                              {batchdetails.batchProgress === "Scheduled" ? <option key="" value="">Scheduled</option> : ""}
                              {batchdetails.batchProgress === "Scheduled" ? <option key="InProgress" value="InProgress">InProgress</option> : ""}
                              {batchdetails.batchProgress === "Scheduled" ? <option key="Cancelled" value="Cancelled">Cancelled</option> : ""}

                              {batchdetails.batchProgress === "InProgress" ? <option key="" value="">InProgress</option> : ""}
                              {isCompletedVisible && (
                                <option key="Completed" value="Completed">Completed</option>
                              )}
                              {batchdetails.batchProgress === "InProgress" ? <option key="Cancelled" value="Cancelled">Cancelled</option> : ""}


                            </select>


                          </div>
                          <button
                            className=" btn btn-black mr-2 mt-2"
                            onClick={() => setEditBatchStatus(false)}
                          >
                            Cancel
                          </button>
                          {status ? <button className="btn btn-black mt-4 mr-4" onClick={() => UpdateBatchProgress(status)} >Save Changes</button> : ""}
                        </div >
                        :
                        ""
                  }

                </div>

              </div>
              <div className="xl:col-span-6 lg:col-span-6 col-span-12">
                {addStudentClicked ? <div className="bg-white shadow-box7 p-8 rounded-md">
                  <Input
                    placeholder="Search by Name or Phone number"
                    className="from-control"
                    value={searchValue}
                    onChange={handleSearch}
                    style={{ marginBottom: 16 }}

                  />
                  <Table
                    rowSelection={enrolledrowSelection}
                    className=""
                    columns={columnss}
                    dataSource={filteredData}
                    pagination={filteredData.length > 9 ? true : false}
                    rowKey={(record) => record.key}
                  />
                </div>
                  : <div className="bg-white shadow-box7 p-8 rounded-md">

                    <Table
                      className=""
                      columns={columns}
                      dataSource={batchdetails && batchdetails.StudentsEndrolled}
                      pagination={batchdetails && batchdetails.StudentsEndrolled.length > 9 ? true : false}
                      rowKey={(record) => record.key}
                    />

                  </div>}
              </div>

            </div>
            <div className="bg-white shadow-box7 p-8 rounded-md mt-4">
              <Table
                className=""
                columns={columnsClassSchedule}
                dataSource={batchdetails && batchdetails.ClassSchedule}
                pagination={batchdetails && batchdetails.ClassSchedule.length > 9 ? true : false}
                rowKey={(record) => record.scheduleNumber}
              />
            </div>
          </div>
        </div >}

    </>
  )

}
export default EditBatch;