import React, { useState } from "react";
import { useNavigate } from "react-router-dom"
import { h, GoogleIcon, CodomoSignIN } from "../../constant/images";
import { ClassicSpinner } from "react-spinners-kit";
{/*
import { Icon, InlineIcon } from "@iconify/react";
*/}



const SignInForm = (props) => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [eye, seteye] = useState(false);
  const navigate = useNavigate()

  function handleChange(event) {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/
    const mobilePattern = /^\d{10}$/
    props.setShowOtpColoumn(false)
    setOtp()
    if (emailPattern.test(event.target.value) || mobilePattern.test(event.target.value)) {
      setEmailOrPhone(event.target.value);
      console.log("match")
    } else { console.log("no match"); setEmailOrPhone() }
  }

  function otpChange(event) {
    const otpPattern = /^\d{4,6}$/
    if (otpPattern.test(event.target.value)) {
      setOtp(event.target.value);
    } else { setOtp() }
  }
  const onProceed = () => {
    props.FetchData({ emailOrPhone: emailOrPhone.toLowerCase() })
  }

  const ResendOtp = () => {
    props.FetchData({ emailOrPhone: emailOrPhone.toLowerCase(), resendOtp:true })
    console.log("ResendOTP received")
  }

  const onsubmit = () => {
    const sessionId = localStorage.getItem("plivo_session_uid")

    console.log("session Is", sessionId)
    props.FetchData({ emailOrPhone: emailOrPhone.toLowerCase(), user_otp: otp, session_uuid: sessionId })
  }
  {/*const changeEye = () =>{
        seteye(!eye)
  }
*/}
  return (
    <div className="about-area  section-padding-top-half pb-16 relative z-[1] max-h-100">
      <div className=" absolute right-[7%] top-[20%] z-[-1] hidden xl:block">
        <img src={h} alt="" />
      </div>
      <div className="container">
        <div className="grid grid-cols-12  gap-10">

          <div className="xl:col-span-5 lg:col-span-5 col-span-12 ">
            <div className="mini-title">Sign In</div>
            <h4 className="column-title ">
              {" "}
              <span className="shape-bg">Codomo</span>
              <p>
                The Tech Loop
              </p>

            </h4>
            {props.usedGoogle ?
              <div className="spinner-center">
                <ClassicSpinner size={25} color="black" loading={props.usedGoogle} />
              </div>
              :
              <div>
                <div>
                  Sign In With Your Email / Phone Number
                </div>
                <ul className=" list-item space-y-6 pt-8">

                  <li className="flex">
                    <div className="flex-none mr-6 from-control-1 focus:ring-0" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="text"
                        placeholder="Email/Phone"
                        className="from-control-edit focus:ring-0"
                        onChange={handleChange}
                        disabled={props.proceedLoading}
                        onKeyDown={event => {
                          if (event.key === 'Enter' || event.code === 'Enter' || event.key === 'NumpadEnter' || event.code === 'NumpadEnter') {
                            emailOrPhone && onProceed();
                          }
                        }}
                      />
                      {props.showOtpColoumn ?
                        ""
                        :
                        props.proceedLoading ?
                          <ClassicSpinner size={25} color="grey" loading={props.proceedLoading} />
                          : <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em' }}
                            onClick={() => emailOrPhone && onProceed()} />
                      }

                    </div>
                  </li>
                  <li className="flex">
                    {props.showOtpColoumn ?
                      <div className="flex-none mr-6 from-control-1 focus:ring-0" style={{ display: 'flex', alignItems: 'center' }}>
                        <iconify-icon icon={eye ? "ic:round-remove-red-eye" : "mdi:eye-off"} style={{ colour: 'red' }} onClick={() => seteye(!eye)} />
                        <input
                          type={eye ? "text" : "password"}
                          placeholder="Enter The OTP"
                          maxLength="6"
                          className="from-control-edit focus:ring-0"
                          onChange={otpChange}
                          disabled={props.proceedLoading}
                          onKeyDown={event => {
                            if (event.key === 'Enter' || event.code === 'Enter' || event.key === 'NumpadEnter' || event.code === 'NumpadEnter') {
                              otp && onsubmit();
                            }
                          }} />
                        {props.proceedLoading ?
                          <ClassicSpinner size={25} color="grey" loading={props.proceedLoading} />
                          : <iconify-icon icon="material-symbols:arrow-circle-right-outline" style={{ fontSize: '2em' }}
                            onClick={onsubmit} />
                        }
                      </div>
                      :
                      ""}

                  </li>

                  {props.showOtpColoumn ?
                    <div className="resend-otp-text"> Haven't received the code yet?
                      <div className="resend-otp" onClick={ResendOtp}>Resend Code</div>
                    </div>
                    :
                    <div>
                      <li >
                        <div className="flex-none">
                          <button className="btn btn-black block text-center " style={{ display: 'flex', alignItems: 'center' }} onClick={() => props.GoogleLoginFunction()}>
                            <img
                              className="center"
                              alt=""
                              src={GoogleIcon}
                              style={{ marginRight: '0.5em' }} />
                            Sign In Using Google

                          </button> </div>

                        <div className="resend-otp-text "> New Here?
                          <div className="resend-otp-red" onClick={() => navigate("/signUp")}>Let's Get Started</div>
                        </div>
                      </li>
                    </div>
                  }

                </ul> </div>}
          </div>
          <div className="xl:col-span-7 lg:col-span-7 col-span-12" >
            <img src={CodomoSignIN} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInForm;